<div class="main-header">
    <div class="logo">
        <img #logo src="https://qbitt.io/assets/images/logo.png" alt="img-fluid">
    </div>

    <div class="menu-toggle d-block d-md-block d-lg-none" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right" *ngIf="user">
<!--        <i class="i-Magnifi-Glass1 header-icon" role="button" (click)="searchService.searchOpen = true"></i>-->

        <div ngbDropdown [placement]="'bottom-right'">
            <div class="badge-top-container outline-none" routerLink="/notification">
                <span class="badge badge-primary" *ngIf="navService.notificationCount > 0">{{navService.notificationCount}}</span>
                <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
            </div>
<!--            <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>-->
<!--                <div class="dropdown-item d-flex" *ngFor="let item of notifications" [routerLink]="[item.link]">-->
<!--                    <div class="notification-icon">-->
<!--                        <i class="{{item.icon}} text-{{item.status}} mr-1"></i>-->
<!--                    </div>-->
<!--                    <div class="notification-details flex-grow-1">-->
<!--                        <p class="m-0 d-flex align-items-center">-->
<!--                            <span>{{item.title}}</span>-->
<!--                            <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ml-1 mr-1">{{item.badge}}</span>-->
<!--                            <span class="flex-grow-1"></span>-->
<!--                            <span class="text-small text-muted ml-auto">{{item.time | relativeTime}}</span>-->
<!--                        </p>-->
<!--                        <p class="text-small text-muted m-0">{{item.text | excerpt:30}}</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
            <img src="assets/images/user-placeholder-img.png" ngbDropdownToggle alt="" class="cursor-pointer" *ngIf="!user.profileImage">
            <img [src]="serverUrl + user.profileImage" ngbDropdownToggle alt="" class="cursor-pointer" *ngIf="user.profileImage">

            <div ngbDropdownMenu class="header-account-dropdown">
                <button class="dropdown-item" routerLink="/settings/general">Settings</button>
                <button class="dropdown-item" routerLink="/profile">Profile</button>
                <button class="dropdown-item" (click)="signout()">Logout</button>
            </div>
        </div>
    </div>
</div>



<!--loading spinner-->
<div class="loading-spinner" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<!--loading spinner-->
