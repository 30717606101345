import {Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from '@angular/router';
import {NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import {LocalStoreService} from './shared/services/local-store.service';
import { AuthServiceLocal } from './shared/services/auth.service';
import {Meta, Title} from '@angular/platform-browser';
import { NavigationEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';
import { filter } from 'rxjs/operators';  
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Qbitt';

  moduleLoading = false;
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  changedText: string;

  constructor(
      private router: Router, private route: ActivatedRoute, private ccService: NgcCookieConsentService,
      private store: LocalStoreService, private auth: AuthServiceLocal, private meta: Meta, private pageTitle: Title,
      private cd: ChangeDetectorRef, private titleService: Title,
  ) {

    console.log("Build Information : 13-09-2024");
    
    let viewPref  = document.getElementsByClassName('viewPref')
    // console.log("hello",viewPref)
     viewPref[0].addEventListener('click',() => {     
      document.getElementById('changetext').style.display = "none";
      document.getElementById('view-preference').style.display = 'block'; 
      document.getElementById('save-pref').style.display = 'block'; 
    
    })



  

    let arrow = document.getElementsByClassName('arrow')  
    arrow[0].addEventListener('click',() => {
      document.getElementById('function-sec').style.display = document.getElementById('function-sec').style.display =='block'? 'none':'block';    
      })

    let statistic = document.getElementsByClassName('statistic-arrow')  
    statistic[0].addEventListener('click',() => {
      document.getElementById('statistics-sec').style.display = document.getElementById('statistics-sec').style.display =='block'? 'none':'block';    
      })

      let marketing = document.getElementsByClassName('marketing')  
      marketing[0].addEventListener('click',() => {
      document.getElementById('marketing-sec').style.display = document.getElementById('marketing-sec').style.display =='block'? 'none':'block';    
      })


    this.checkCookieConsent();
    
    this.router.events.pipe(
      filter( event => event instanceof NavigationEnd)
    ).subscribe((e: any) => {
      if(e instanceof NavigationEnd) {
        this.setPageTitle();
      }
    })
   
  }


  setPageTitle() {
    this.titleService.setTitle('Manage Your Calendar & Schedule with Local Businesses | Qbitt')
  }

  ngOnInit() {
    // this.disableInspect();
    // meta tags
    this.meta.addTags([
      { name: 'keywords', content: '' },
      { name: 'description', content: 'Search and book appointments with local businesses trusted by your friends and family.' },
      { name: 'author', content: 'Qbitt' },
      { name: 'publisher', content: 'Qbitt' },
      { name: 'robots', content: 'noindex, nofollow' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'Qbitt'},
      { property: 'og:locale', content: 'en-US' },
      { property: 'og:description', content: 'Search and book appointments with local businesses trusted by your friends and family.' },
    ]);
    // meta tags

    // Progress bar
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
          this.moduleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        setTimeout(() => {
          this.moduleLoading = false;
        }, 500);
      }
    });
    // progress bar

    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
          const k = this.ccService.getStatus();
          // console.log('getStatus :', k);
        });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
        (event: NgcInitializeEvent ) => {
          // you can use this.ccService.getConfig() to do stuff...
          // console.log('event NgcInitializeEvent:', event);
        });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
          // console.log('event NgcStatusChangeEvent:', event);
          if (event.status === 'dismiss') {
            this.setCookie('QB_COOKIE_CONSENT', '1', 300, '');
          }
        });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
        });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          // you can use this.ccService.getConfig() to do stuff...
        });

        this.delclineCookies()

  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  setCookie(name: string, value: string, expireDays: number, path: string) {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  getCookie(name: string) {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    // const c: string;
  }

  checkCookieConsent() {
    // @ts-ignore
    if (this.getCookie('QB_COOKIE_CONSENT') === '1') {
      this.ccService.destroy();
      // this.ccService.close(true);
      // console.log('reached');
    }
  }

  /**
   * function used to disable inspect and ctrl keys
   */
  disableInspect() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.onkeydown = (e) => {
      if (e.keyCode === 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        return false;
      }
    };
  }



  ngOnDestroy() {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }

  delclineCookies(){
    // console.log("delclineCookies0")
   

  }
 
    
    

}
