import {AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthServiceLocal } from '../../../../services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {LocalStoreService} from '../../../../services/local-store.service';
import {ServerUrl} from '../../../../@utils/systemEnums';
import {SettingService} from '../../../../services/my-services/settings.service';
import {ProfileService} from '../../../../services/my-services/profile.service';
import {LoadingService} from '../../../../services/my-services/loading.service';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked {
    loading = false;
    notifications: any[];
    authenticated = false;
    serverUrl = ServerUrl;
    user; unReadNotifications;

    constructor(
      public navService: NavigationService, private settingService: SettingService,
      public searchService: SearchService, private router: Router,
      private auth: AuthServiceLocal, private toaster: ToastrService,
      private store: LocalStoreService, private profileService: ProfileService,
      public loadingService: LoadingService, private cd: ChangeDetectorRef
    ) {
      this.getProfile();
    }

    ngOnInit() {
      setTimeout(() => {
          this.unReadNotifications = this.auth.getUnreadNotification();
      }, 3000);
    }

    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }

    ngAfterViewChecked(): void {
        this.cd.detectChanges();
    }

    ngAfterContentChecked(): void {
        this.cd.markForCheck();
    }

    getProfile() {
        this.loadingService.showLoadingSpinner = true;
        this.profileService.getProfileDetails().subscribe(res => {
          const Response: any = res;
          if (Response.status === 200) {
            this.user = Response.data.user;
            this.loadingService.showLoadingSpinner = false;
          } else {
            this.loadingService.showLoadingSpinner = false;
          }
        });
    }

    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      // item has child items
      if (!state.sidenavOpen && !state.childnavOpen && this.navService.selectedItem.type === 'dropDown') {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
      // item has no child items
      if (!state.sidenavOpen && !state.childnavOpen) {
        state.sidenavOpen = true;
      }
    }

    signout() {
      this.loadingService.showLoadingSpinner = true;
      this.auth.logOut({origin: ''}).subscribe((res: Response) => {
        const Res: any = res;
        if (Res.status === 200) {
          this.authenticated = false;
          this.loadingService.showLoadingSpinner = false;
          this.toaster.success(Res.message, 'Log Out', {progressBar: true});
          this.router.navigate(['/']);
          this.store.clear();
        } else {
          this.loadingService.showLoadingSpinner = false;
          this.toaster.error(Res.message, 'Error', {progressBar: true});
          return false;
        }
      });
    }

}
